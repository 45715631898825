import { Box, SimpleGrid, Text, Stack, Heading, Container, Button } from '@chakra-ui/react';

interface FeatureProps {
  title: string;
  text: string;
}

const Feature = ({ title, text }: FeatureProps) => {
  return (
    <Stack>
      <Text fontWeight={600}>{title}</Text>
      <Text color={'gray.600'}>{text}</Text>
    </Stack>
  );
};

export default function Features() {
  return (
    <Box p={4}>
      <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'} marginBottom="10px">
        <Heading fontSize={'3xl'}>Garlic Press Rocker - Zenturie Kitchens</Heading>
        <Text color={'gray.600'} fontSize={'xl'}>
          For simple, fun and efficient crushed and minced garlic.
        </Text>
      </Stack>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} marginTop="50px">
        <Feature
          title={'Making Crushed Garlic'}
          text={
            "Crushed garlic is simple and easy. Peel the cloves by crushing them, by lightly pressing down on them with the rounded end of the crusher. After the are peeled, push down on the garlic with the rounded edge."
          }
        />
        <Feature
          title={'Making Minced Garlic'}
          text={
            'After successfully crushing the garlic, press down on the garlic with the rounded egde and simply rock the rocker back and forth.'
          }
        />
      </SimpleGrid>
      <Stack spacing={4} as={Container} maxW={'sm'} textAlign={'center'} marginTop="50px">
        <Button as="a" colorScheme='blue' href="https://amazon.com/dp/B09SZNDQKP" maxW='sm'>Buy Now At Amazon.com</Button>
      </Stack>
    </Box>
  );
}