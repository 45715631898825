import React, { useState } from 'react'
import {
  Container,
  Flex,
  Box,
  Heading,
  Text,
  Button,
  VStack,
  Wrap,
  WrapItem,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Textarea,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
} from '@chakra-ui/react'
import { MdEmail } from 'react-icons/md'
import { BsPerson } from 'react-icons/bs'
import emailjs from '@emailjs/browser'

export default function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure()

  const nameChange = (e: any) => {
    setName(e.currentTarget.value);
  };

  const emailChange = (e: any) => {
    setEmail(e.currentTarget.value);
  };

  const messageChange = (e: any) => {
    setMessage(e.currentTarget.value);
  };

  const handleSubmit = (e: any) => {
    
    const data = {
      "user_name" : name,
      "user_email" : email,
      "user_message" : message,
    };
    
    e.preventDefault();

    emailjs.send('service_58xoz1h', 'template_fi3exa6', data, 'mLnIPIRCec4rhR998')
      .then((result) => {
        onOpen();
      }, (error) => {});
  };

  return (
    <Container maxW="full" mt={0} centerContent overflow="hidden" id="contact">
      <Flex>
        <Box
          bg="#02054B"
          color="white"
          borderRadius="lg"
          m={{ sm: 4, md: 16, lg: 10 }}
          p={{ sm: 5, md: 5, lg: 16 }}>
          <Box p={4}>
            <Wrap spacing={{ base: 20, sm: 3, md: 5, lg: 20 }}>
              <WrapItem>
                <Box>
                  <Heading>Contact</Heading>
                  <Text mt={{ sm: 3, md: 3, lg: 5 }} color="gray.500">
                    Fill up the form below to contact
                  </Text>
                </Box>
              </WrapItem>
              <WrapItem>
                <Box bg="white" borderRadius="lg">
                  <Box m={8} color="#0B0E3F">
                  <form onSubmit={handleSubmit}>
                    <VStack spacing={5}>
                        <FormControl id="name" isRequired>
                          <FormLabel>Your Name</FormLabel>
                          <InputGroup borderColor="#E0E1E7">
                            <InputLeftElement
                              pointerEvents="none"
                              children={<BsPerson color="gray.800" />}
                            />
                            <Input 
                              type="text" 
                              size="md"
                              onChange={nameChange}
                            />
                          </InputGroup>
                        </FormControl>
                        <FormControl id="name" isRequired>
                          <FormLabel>Mail</FormLabel>
                          <InputGroup borderColor="#E0E1E7">
                            <InputLeftElement
                              pointerEvents="none"
                              children={<MdEmail color="gray.800" />}
                            />
                            <Input 
                              type="text" 
                              size="md"
                              onChange={emailChange}
                            />
                          </InputGroup>
                        </FormControl>
                        <FormControl id="name" isRequired>
                          <FormLabel>Message</FormLabel>
                          <Textarea
                            borderColor="gray.300"
                            _hover={{
                              borderRadius: 'gray.300',
                            }}
                            placeholder="message"
                            onChange={messageChange}
                          />
                        </FormControl>
                        <FormControl id="name" float="right">
                          <Button
                            variant="solid"
                            bg="#0D74FF"
                            color="white"
                            _hover={{}}
                            type="submit"
                          >
                            Send Message
                          </Button>
                        </FormControl>
                    </VStack>
                    </form>
                  </Box>
                </Box>
              </WrapItem>
            </Wrap>
          </Box>
        </Box>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Your message has been successfully sent.</ModalHeader>
          <ModalCloseButton />
        </ModalContent>
      </Modal>
    </Container>
  );
}