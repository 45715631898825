import * as React from "react"
import { Flex } from "@chakra-ui/react"

export default function Layout(props: any) {
  return (
    <Flex
      direction="column"
      align="left"
      maxW={{ xl: "1000px" }}
      m="0 auto"
      {...props}
    >
      {props.children}
    </Flex>
  )
}