import * as React from "react"
import {
  ChakraProvider
} from "@chakra-ui/react"
import Navbar from './components/navbar'
import Carousel from './components/carousel'
import Layout from './components/layout'
import Features from './components/feature'
import Contact from './components/contact'
import Footer from './components/footer'
import Cookie from './components/cookies'

export default function App() {
  // 2. Wrap ChakraProvider at the root of your app
  return (
    <ChakraProvider>
      <Layout>
        <Navbar />
        <Carousel />
        <Features />
        <Contact />
        <Footer />
        <Cookie />
      </Layout>
    </ChakraProvider>
  )
}
