import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';
import { StringHelper } from '../utils/string_helper';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';



export default function Cookie() {
  const handleAcceptCookie = () => {
    if (process.env.REACT_APP_GTAG_ID) {
      ReactGA.initialize(StringHelper.extractStringEnvVar('REACT_APP_GTAG_ID'));
      ReactGA.send("pageview")
    }
  };

  const handleDeclineCookie = () => {
    //remove google analytics cookies
    Cookies.remove("_ga");

    if (process.env.REACT_APP_GTAG_ID) {
      Cookies.remove("_ga_" + StringHelper.extractStringEnvVar('REACT_APP_GTAG_ID'));
    }
  };

  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleAcceptCookie();
    }
  }, []);

  return(
    <CookieConsent
      enableDeclineButton
      onAccept={handleAcceptCookie}
      onDecline={handleDeclineCookie}
    >
      This website uses cookies to enhance the user experience.
    </CookieConsent>

  );
}